<template>
  <InputGroup>

    <InputText
      :value="value"
      type="email"
      ref="input"
      :name="name"
      autocomplete="email"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />

    <template #after>
      <BaseButton
        variant="inputlike"
        size="square"
        @click="insertAtSign($event)"
      >
        @
      </BaseButton>
    </template>
  </InputGroup>
</template>

<script>
import InputGroup from '@gds/components/InputGroup';
import InputText from '@gds/components/InputText';
import BaseButton from '@gds/components/BaseButton';

// TODO: Don't trigger change on button click
export default {
  name: 'InputEmail',
  components: {
    InputGroup,
    InputText,
    BaseButton,
  },
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  methods: {
    insertAtSign() {
      this.$emit('input', `${this.value}@`);
      this.$refs.input.$el.focus();
    },
  },
};
</script>
