<!-- eslint-disable max-len -->
<template>
  <div>
    <FormGroup
      label="Telefón"
      :rules="['required', 'phone']"
      :tooltipText="showPhoneText && !isBeforeConfirmation
        ? 'Na zadané telefónne číslo vás môže jednorazovo kontaktovať náš operátor ohľadom vytvorenia nezáväznej ponuky. Číslo po 15 dňoch vymažeme'
        : null"
    >
      <InputPhone v-model="value.phone" />
      <template #afterMessage v-if="showPhoneText && !isBeforeConfirmation">
        <BaseParagraph style="margin: 0">
          Radi vám pomôžeme s nezáväzným nastavením poistenia. Číslo budeme uchovávať len 15 dní.
          <BaseLink @click.prevent="$modal.show('privacyPolicy')">
            Viac info o&nbsp;spracovaní osobných údajov.
          </BaseLink>
        </BaseParagraph>
      </template>
    </FormGroup>

    <FormGroup
      :label="isBeforeConfirmation ? 'E-mail' : 'E-mail (nepovinné)'"
      :rules="isBeforeConfirmation ? ['required', 'email'] : ['email']"
    >
      <InputEmail v-model="value.email" />
    </FormGroup>

    <ModalPrivacyPolicy/>
  </div>
</template>

<script>
import BaseParagraph from '@gds/components/BaseParagraph';
import BaseLink from '@gds/components/BaseLink';
import FormGroup from '@gds/components/FormGroup';
import InputPhone from '@gds/components/InputPhone';
import InputEmail from '@gds/components/InputEmail';
import ModalPrivacyPolicy from './ModalPrivacyPolicy.vue';

export default {
  props: {
    value: Object,
    isBeforeConfirmation: Boolean,
    showPhoneText: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BaseParagraph,
    BaseLink,
    FormGroup,
    InputPhone,
    InputEmail,
    ModalPrivacyPolicy,
  },
};
</script>
