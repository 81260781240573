<!-- eslint-disable max-len -->
<template>
  <ModalWindow
    name="privacyPolicy"
    title="Informácie o spracúvaní osobných údajov"
  >
    <p>V tomto texte vám poskytneme informácie o spracúvaní vašich osobných údajov podľa článkov 13 a 14 Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov (ďalej len „Nariadenie“).</p>

    <p>Dozviete sa:</p>

    <ul>
      <li>kto je prevádzkovateľom vašich osobných údajov,</li>
      <li>prečo spracúvame vaše osobné údaje,</li>
      <li>kto je Dotknutou osobou,</li>
      <li>aké kategórie osobných údajov spracúvame,</li>
      <li>komu môžeme poskytnúť vaše osobné údaje,</li>
      <li>ako dlho uchovávame vaše osobné údaje,</li>
      <li>či dochádza k profilovaniu,</li>
      <li>aké sú vaše práva ako Dotknutej osoby,</li>
      <li>koho kontaktovať, ak máte otázky k nášmu spracúvaniu vašich osobných údajov</li>
    </ul>

    <h2>Kto je prevádzkovateľ osobných údajov?</h2>

    <p>Prevádzkovateľ je ten, kto spracúva vaše osobné údaje. V tomto prípade sme prevádzkovateľom my = Generali Česká pojišťovna a.s., IČO 452 72 956, so sídlom Spálená 75/16, Nové Město, 110 00 Praha 1, Česká republika, zapísaná v obchodnom registri Mestského súdu v Prahe, oddiel B, vložka 1464, podnikajúca na Slovensku prostredníctvom organizačnej zložky podniku zahraničnej osoby: Generali Poisťovňa, pobočka poisťovne z iného členského štátu (používateľ ochranných známok Európska cestovná poisťovňa a Genertel), IČO: 54 228 573, so sídlom Lamačská cesta 3/A, 841 04 Bratislava, zapísaná v Obchodnom registri Mestského súdu Bratislava III, oddiel Po, vložka č. 8774/B.</p>

    <h2>Prečo spracúvame osobné údaje?</h2>

    <p>Sme povinní uviesť účely spracúvania osobných údajov. Účel spracúvania znamená vlastne dôvod, pre ktorý spracúvame vaše osobné údaje. Osobné údaje spracúvame najmä na tieto účely:</p>

    <ul>
      <li>uzatvorenie, evidencia a správa poistných zmlúv. Správa poistných zmlúv znamená aj vykonávanie starostlivosti o klienta (napr. služba elektronickej korešpondencie a klientska zóna),</li>
      <li>hlásenie (reporting), výpočet kapitálových požiadaviek, štatistické spracovanie a ocenenie nových produktov,</li>
      <li>likvidácia poistných udalostí,</li>
      <li>zaistenie,</li>
      <li>uzatvorenie poistenia, správa poistných zmlúv, elektronické podpisovanie zmlúv, identifikácia klienta, overenie identifikácie a ďalšie účely podľa Zákona o poisťovníctve prostredníctvom biometrického podpisu a tvárovej biometrie,</li>
      <li>kontrola kvality a uchovanie podkladov pri uzatváraní poistných zmlúv prostredníctvom hlasových záznamov (napr. pri telefonickom uzavretí poistnej zmluvy),</li>
      <li>vybavovanie sťažností a iných podnetov,</li>
      <li>riadenie rizika podvodu a oznamovanie protispoločenskej činnosti,</li>
      <li>evidencia hlásení o neobvyklých obchodných operáciách a identifikácia klienta s cieľom vykonania starostlivosti vo vzťahu ku klientovi,</li>
      <li>vedenie súdnych sporov a mimosúdne vymáhanie pohľadávok,</li>
      <li>spracúvanie osobných údajov v rámci účtovných dokladov,</li>
      <li>vybavenie podnetov zaslaných prostredníctvom kontaktného formulára,</li>
      <li>ponuka produktov a služieb a poskytovanie informácie v rámci priameho marketingu,</li>
      <li>súťaže,</li>
      <li>správa registratúry,</li>
      <li>výkon vnútorného auditu, výkon funkcie súladu s predpismi, výkon funkcie riadenia rizík,</li>
      <li>medzinárodná výmena daňových informácií (FATCA, CRS/DAC2)</li>
    </ul>

    <h2>Aký je právny základ na spracúvanie vašich osobných údajov?</h2>

    <p>Spracúvanie je zákonné, len ak sa vykonáva podľa právnych predpisov. Právne predpisy nám dovoľujú spracúvať osobné údaje len v určitých prípadoch. Hovoríme tomu právny základ spracúvania. Pri spracúvaní vašich osobných údajov využívame najmä nasledujúce právne základy:</p>

    <ul>
      <li>plnenie zákonných povinností, ktoré nám stanovuje najmä Zákon o poisťovníctve,</li>
      <li>uzatvorenie a plnenie poistnej zmluvy,</li>
      <li>oprávnený záujem, ktorým je ochrana našich práv a právom chránených záujmov (napr. účel riadenia rizika podvodu, ponuka produktov a služieb a poskytovanie informácií v rámci priameho marketingu),</li>
      <li>súhlas Dotknutej osoby.</li>
    </ul>

    <h2>Kto je dotknutá osoba?</h2>

    <p>Dotknutá osoba je osoba, ktorej osobné údaje spracúvame. Ak spracúvame vaše osobné údaje, ste Dotknutou osobou vy. Spracúvame osobné údaje najmä o poistníkovi, poistenom, osobe oprávnenej na prevzatie poistného plnenia, ďalších osobách uvedených v poistnej zmluve, ako aj o možných budúcich klientoch. V tomto dokumente ju označujeme ako Dotknutá osoba.</p>

    <h2>Aké kategórie osobných údajov spracúvame?</h2>

    <p>Spracúvame tieto kategórie osobných údajov:</p>

    <ul>
      <li>identifikačné údaje (napr. meno, priezvisko, dátum narodenia a rodné číslo),</li>
      <li>kontaktné údaje (napr. adresa trvalého pobytu, telefónne číslo a e-mailová adresa),</li>
      <li>sociodemografické údaje (napr. vek a povolanie),</li>
      <li>citlivé údaje (napr. údaje o zdraví),</li>
      <li>údaje o poistných zmluvách, ktoré má Dotknutá osoba s nami uzavreté.</li>
    </ul>

    <h2>Komu môžeme poskytnúť vaše osobné údaje?</h2>

    <p>Týmto príjemcom/organizáciám/osobám môžeme poskytnúť vaše osobné údaje</p>

    <p>finanční agenti, Slovenská kancelária poisťovateľov, zmluvní partneri (napr. poskytovatelia IT služieb), Sociálna poisťovňa, advokáti, Národná banka Slovenska, posudkoví lekári, exekútori, znalci, orgány činné v trestnom konaní (napr. prokuratúra, polícia), Slovenská asociácia poisťovní, súdy</p>

    <h2>Ako dlho uchovávame osobné údaje?</h2>

    <p>Máme právo spracúvať osobné údaje Dotknutej osoby. Toto právo máme na základe Zákona o poisťovníctve. Máme právo spracúvať vaše osobné údaje počas trvania vašej poistnej zmluvy. Najdlhšie budeme uchovávať vaše osobné údaje 10 rokov po tom, ako sa skončí platnosť všetkých vašich poistných zmlúv. Právo spracúvať vaše osobné údaje máme aj vtedy, ak ste nám udelili súhlas. V takom prípade môžeme spracúvať osobné údaje len počas takého obdobia, na ktoré ste nám súhlas udelili.</p>

    <h2>Dochádza k profilovaniu?</h2>

    <p>Áno, pri spracúvaní vašich údajov môže dochádzať aj k profilovaniu. Profilovanie je aj členenie nových klientov na základe konkrétnych údajov. Aké sú to údaje? Profilovanie vykonávame na základe</p>

    <ul>
      <li>sociálno-demografických údajov (napr. vek, zamestnanie, veľkosť bydliska),</li>
      <li>údajov o produktoch (napr. či máte u nás iné poistenie, výška poistného).</li>
    </ul>

    <p>Profilovanie vykonávame preto, aby sme zabezpečili</p>

    <ul>
      <li>ponuku zohľadňujúcu vaše potreby,</li>
      <li>správny výpočet poistného,</li>
      <li>riadnu správu poistnej zmluvy.</li>
    </ul>

    <h2>Aké sú práva Dotknutej osoby?</h2>

    <p>Ako Dotknutá osoba máte právo:</p>

    <ul>
      <li>požadovať od nás prístup k svojim osobným údajom,</li>
      <li>požadovať, aby sme opravili vaše nesprávne alebo neúplné osobné údaje,</li>
      <li>požadovať, aby sme vymazali vaše osobné údaje,</li>
      <li>požadovať, aby sme obmedzili spracúvanie vašich osobných údajov,</li>
      <li>namietať proti spracúvaniu svojich osobných údajov,</li>
      <li>požadovať, aby sme preniesli vaše osobné údaje do inej spoločnosti,</li>
      <li>odvolať súhlas (ak spracúvame osobné údaje na základe vášho súhlasu),</li>
      <li>podať sťažnosť dozornému orgánu, ktorým je Úrad na ochranu osobných údajov Slovenskej republiky.</li>
    </ul>

    <p>Tieto práva sú bližšie opísané v článkoch 15 až 21 Nariadenia. Ako Dotknutá osoba si uvedené práva môžete uplatniť v súlade s Nariadením a ďalšími príslušnými právnymi predpismi. Ak si chcete uplatniť svoje právo voči nám, môžete tak urobiť prostredníctvom písomnej (listinnej) žiadosti alebo elektronickými prostriedkami (e-mailom). V prípade, že si nebudeme istí, či ste to skutočne vy, môžeme Vás požiadať o poskytnutie ďalších informácií alebo o podanie žiadosti s úradne overeným podpisom.</p>

    <h2>Ako sa spojiť s osobou, ktorá je u nás zodpovedná za ochranu osobných údajov?</h2>

    <p>Ak by ste sa potrebovali spojiť s osobou, ktorá je u nás zodpovedná za dohľad nad ochranou osobných údajov, napíšte e-mail alebo pošlite list na:</p>

    <p><strong>Generali Česká pojišťovna a.s.,</strong><br>
    Osoba zodpovedná za dohľad nad ochranou osobných údajov<br>
    Na Pankráci 1720/123,<br>
    140 00 Praha 4,<br>
    Česká republika<br>
    E-mail: dpo@generaliceska.cz.</p>

    <p>Alebo prostredníctvom kontaktnej osoby na Slovensku:</p>

    <p><strong>Generali Poisťovňa, pobočka poisťovne z iného členského štátu</strong><br>
    Osoba zodpovedná za dohľad nad ochranou osobných údajov<br>
    Lamačská cesta 3/A<br>
    841 04 Bratislava<br>
    E-mail: dpo.sk@generali.com.</p>

    <p>Podrobné informácie o spracúvaní osobných údajov sú uvedené na našej webovej stránke: https://www.generali.sk alebo na našich obchodných miestach.</p>
    <template #footer>
      <BaseButton
        variant="secondary"
        @click="$modal.hide('privacyPolicy')"
      >
        Zavrieť
      </BaseButton>
    </template>

  </ModalWindow>
</template>

<script>
import ModalWindow from '@gds/components/ModalWindow';
import BaseButton from '@gds/components/BaseButton';

export default {
  name: 'ModalPrivacyPolicy',
  components: {
    ModalWindow,
    BaseButton,
  },
};
</script>
