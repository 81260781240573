<template>
  <div>
    <div class="container">
      <FormGroup
        label="Vaša právna forma"
        :rules="['requiredOption']"
      >
        <InputOptions
          :options="optionsPartyType"
          v-model="customer.type"
          variant="icon"
        />
      </FormGroup>

      <FormGroup
        v-if="customer.type === 'PERSON'"
        label="Rodné číslo"
        tooltipText="Vaše osobné údaje sú prenášané na server zabezpečeným spojením."
        helpText="Zadajte v tvare 740314/0910"
        :rules="[
          'required',
          'nationalIdentificationNumber',
        ]"
        key="nationalIdentificationNumber"
      >
        <InputNationalIdentificationNumber
          v-model="customer.nationalIdentificationNumber"
        />
      </FormGroup>

      <FormGroup
        v-if="customer.type !== 'PERSON'"
        :rules="['required', 'companyRegistrationNumber']"
        label="IČO"
        key="companyRegistrationNumber"
      >
        <InputCompanyRegistrationNumber
          v-model="customer.companyRegistrationNumber"
        />
      </FormGroup>

      <FormGroup
        v-if="customer.type === 'PERSON'"
        label="PSČ trvalého pobytu"
        tooltipText="Tento údaj potrebujeme preto, že výška poistenia sa mení podľa toho, kde žijete."
        :rules="['required', 'postalCode']"
        key="postalCodePersonal"
      >
        <InputText
          v-model.trim="customer.address.postalCode"
          inputmode="numeric"
          autocomplete="postal-code"
        />
      </FormGroup>

      <FormGroup
        v-if="customer.type !== 'PERSON'"
        label="PSČ sídla"
        tooltipText="Tento údaj potrebujeme preto, že výška poistenia sa mení podľa toho, kde sídlite."
        :rules="['required', 'postalCode']"
        key="postalCodeBusiness"
      >
        <InputText
          v-model.trim="customer.address.postalCode"
          inputmode="numeric"
          autocomplete="postal-code"
        />
      </FormGroup>

      <InputContact
        v-model="$store.state.calculation.customer"
      />

      <FormGroup>
        <InputCheckbox
          v-model="agreements.marketingAgreement"
          :infoButton="true"
          @info-button-click="$modal.show('marketingAgreement')"
        >
          Súhlasím so spracovaním osobných údajov na marketingové účely
        </InputCheckbox>
      </FormGroup>
    </div>

    <ModalMarketingAgreement
      name="marketingAgreement"
      @agree="agreements.marketingAgreement = true"
      @disagree="agreements.marketingAgreement = false"
    />
  </div>
</template>

<script>
import { faHeadSide, faBriefcase, faBuilding } from '@fortawesome/pro-light-svg-icons';

import FormGroup from '@gds/components/FormGroup';
import InputOptions from '@gds/components/InputOptions';
import InputText from '@gds/components/InputText';
import InputCheckbox from '@gds/components/InputCheckbox';
import InputCompanyRegistrationNumber from '@gds/common/calculators/InputParty/InputCompanyRegistrationNumber.vue';
import InputContact from '@gds/common/calculators/InputContact/InputContact.vue';
import ModalMarketingAgreement from '@gds/common/calculators/ModalMarketingAgreement';
import InputNationalIdentificationNumber from '@gds/common/calculators/InputParty/InputNationalIdentificationNumber.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    FormGroup,
    InputOptions,
    InputText,
    InputCheckbox,
    InputCompanyRegistrationNumber,
    ModalMarketingAgreement,
    InputContact,
    InputNationalIdentificationNumber,
  },
  data() {
    return {
      optionsPartyType: [
        { value: 'PERSON', label: 'Osoba', icon: faHeadSide },
        { value: 'SELFEMPLOYED', label: 'SZČO', icon: faBriefcase },
        { value: 'COMPANY', label: 'Firma', icon: faBuilding },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'customer',
      'agreements',
      'productProps',
      'shouldPrefilledOfferFastForward',
    ]),
    birthYearRules() {
      const thisYear = new Date().getFullYear();
      const insuranceStartYear = this.productProps.startDate.getFullYear();
      const insuranceStartYearDifference = insuranceStartYear - thisYear;
      return this.$v.rules.year({
        minAge: 18 - insuranceStartYearDifference,
        maxAge: 120,
        message: 'V deň začiatku poistenia musíte mať minimálne 18 rokov.',
      });
    },
    birthYearAfterMessage() {
      const getCustomerAge = () => {
        const { birthYear } = this.customer;
        const thisYear = new Date().getFullYear();
        if (birthYear >= thisYear - 120) {
          const age = thisYear - birthYear;
          return age;
        }
        return null;
      };

      return getCustomerAge() > 30
        ? 'S vekom pribúdajú skúsenosti. A ako skúsený vodič od nás dostanete lepšiu cenu.'
        : null;
    },
  },
  mounted() {
    if (this.shouldPrefilledOfferFastForward) {
      this.$store.state.prefilledOffer.wasFastForwarded = true;
      this.$v.validator.checkAndContinue(this);
    }
  },
};
</script>
